import React from 'react';
import {useExperiments, useTranslation} from '@wix/yoshi-flow-editor';
import {useControllerProps} from '../../../ControllerContext';
import s from './Total.scss';
import {OrderSummaryDataHook} from '../OrderSummary';
import settingsParams from '../../../../settingsParams';
import {useSettings} from '@wix/tpa-settings/react';
import cx from 'classnames';
import {SPECS} from '../../../../../../domain/specs';

const CartDisclaimers = () => {
  const {t} = useTranslation();
  const {taxOnProduct, isMainCurrency} = useControllerProps().orderStore;
  const {currencyFormat} = useControllerProps().cartStore.cart;
  const {get: getSettings} = useSettings();
  const cartDisclaimer = getSettings(settingsParams.CART_V2_DISCLAIMER) as string;
  const {experiments} = useExperiments();
  const ShouldChangeMaxViewportMobile = experiments.enabled(SPECS.ChangeMaxViewportMobile);

  return (
    <dl>
      <dt>
        {!isMainCurrency && (
          <div
            data-hook={OrderSummaryDataHook.TotalCurrencyConverterDisclaimer}
            className={
              /* istanbul ignore next */ ShouldChangeMaxViewportMobile ? s.totalSubLabel : s.totalSubLabelViewport
            }>
            {t('cart.currency_converter_disclaimer', {mainCurrency: currencyFormat.code})}
          </div>
        )}

        {taxOnProduct && (
          <div
            data-hook={OrderSummaryDataHook.TotalTaxIncludedLabel}
            className={
              /* istanbul ignore next */ ShouldChangeMaxViewportMobile ? s.totalSubLabel : s.totalSubLabelViewport
            }>
            {t('cart.total.taxIncluded')}
          </div>
        )}

        {cartDisclaimer && (
          <div data-hook={OrderSummaryDataHook.TotalCartDisclaimer} className={s.disclaimer}>
            {cartDisclaimer}
          </div>
        )}
      </dt>
    </dl>
  );
};

export const Total = () => {
  const {t} = useTranslation();
  const {shouldDisplaySubtotalTitle} = useControllerProps().orderStore;
  const {convertedTotals} = useControllerProps().cartStore.cart;

  const classNames = cx(s.root, {[s.subTotal]: shouldDisplaySubtotalTitle});

  return (
    <>
      <dl className={classNames}>
        <dt>
          <span data-hook={OrderSummaryDataHook.TotalTitle}>
            {shouldDisplaySubtotalTitle ? t('cart.subtotal') : t('cart.estimated_total')}
          </span>
        </dt>

        <dd
          data-hook={OrderSummaryDataHook.TotalFormattedValue}
          role="status"
          aria-live="assertive"
          id="total-sum"
          data-wix-cart-total-price={OrderSummaryDataHook.TotalFormattedValue}>
          {convertedTotals.formattedTotal}
        </dd>
      </dl>
      <CartDisclaimers />
    </>
  );
};
